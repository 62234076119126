import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Index from './index/index'
import Login from './index/login'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "index-Index",
        redirect: "/main/index",
        component: () => import( "v/index/Index.vue"),
        children: [...Index]
    },
    {
        path: "/pdf",
        name: "pdf-Index",
        component: () => import( "v/pdf/Index.vue"),
        meta: {
            title: "西安交大 | 强基计划初试考点城市选择系统",
            authorization: false
        }
    },
    {
        path: "/login",
        name: "login-Index",
        meta: {
            title: "登录",
        },
        component: () => import("v/login/Index.vue"),
        children: [...Login],
    },
    {path: '*', redirect: "/"}
]

const router = new VueRouter({
    mode: "history",
    routes
})

router.beforeEach((to, from, next) => {
    if (window.location.protocol !== 'https:') {
        // 如果当前不是HTTPS，则重定向到HTTPS
        window.location.href = 'https://' + window.location.hostname +
            (window.location.port ? ':' + window.location.port : '') +
            window.location.pathname +
            window.location.search;
        return false;
    }
    // 记录上一页
    if (from) {
        store.commit("setPrePage", from.path);
    }
    let tokenKey = "index-token",
        toLogin = "/login";
    let token = localStorage.getItem(tokenKey);
    let nextUrl = false;

    if (to.meta && to.meta.authorization && to.meta.authorization === true) {
        store.commit("setPrePage", to.path);
        if (!token) {
            nextUrl = toLogin;
        }
    } else {
        if (to.path === toLogin && token) {
            nextUrl = "/";
        }
    }
    document.title = to.meta.title;
    return nextUrl ? next(nextUrl) : next();
});

export default router
