import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 上一页地址
        previousPage: "",
        indexToken: window.localStorage.getItem('index-token') || "",
        accountInfo: {
            username: window.localStorage.getItem('account-username') || "",
            name: window.localStorage.getItem('account-name') || "",
            sfz: window.localStorage.getItem('account-sfz') || "",
        },
        allLoading: false,
    },
    mutations: {
        // 更新上一页信息
        setPrePage(state, path) {
            state.previousPage = path;
        },
        setToken(state, data) {
            state.indexToken = data;
            window.localStorage.setItem("index-token", data);
        },
        setAllLoading(state, data) {
            state.allLoading = data;
        },
        setAccountInfo(state, data) {
            state.accountInfo = data;
            window.localStorage.setItem("account-username", data.username);
            window.localStorage.setItem("account-name", data.name);
            window.localStorage.setItem("account-sfz", data.sfz);
        },
        loginOut(state) {
            state.indexToken = "";
            state.accountInfo = {
                username: "",
                name: "",
                sfz: "",
            }
            window.localStorage.clear();
        },
    },
    actions: {},
    modules: {}
})
