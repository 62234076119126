export default [
    {
        path: "/main/index",
        name: "main-index",
        component: () => import('v/index/home'),
        meta: {
            title: "西安交大 | 强基计划初试考点城市选择系统",
            authorization: true
        }
    },
    {
        path: "/main/pdf",
        name: "main-pdf",
        component: () => import('v/index/pdf'),
        meta: {
            title: "西安交大 | 强基计划初试考点城市选择系统",
            authorization: true
        }
    },
];
