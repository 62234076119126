import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/root.scss'

Vue.config.productionTip = false
Vue.use(ElementUI);

// import {encodeHandle3DES} from './store/utils'
// Vue.prototype.encodeHandle3DES = encodeHandle3DES;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
